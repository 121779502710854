import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/pro-regular-svg-icons";
import { useLocalization } from "gatsby-theme-i18n";
import parse from "html-react-parser";
import { officeKinds } from "../utils/common";

const style = [
  {
    featureType: "all",
    elementType: "geometry",
    stylers: [
      {
        color: "#202c3e",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        gamma: 0.01,
      },
      {
        lightness: 20,
      },
      {
        weight: "1.39",
      },
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        weight: "0.96",
      },
      {
        saturation: "9",
      },
      {
        visibility: "on",
      },
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        lightness: 30,
      },
      {
        saturation: "9",
      },
      {
        color: "#29446b",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        saturation: 20,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        lightness: 20,
      },
      {
        saturation: -20,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        lightness: 10,
      },
      {
        saturation: -30,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#193a55",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [
      {
        saturation: 25,
      },
      {
        lightness: 25,
      },
      {
        weight: "0.01",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        lightness: -20,
      },
    ],
  },
];

function Map() {
  const { locale } = useLocalization();
  const [activeMarker, setActiveMarker] = React.useState(null);
  const offices = officeKinds(locale)
    .reverse()
    .map(({ value, icon, offices }) =>
      offices?.map((item) => ({ ...item, icon, kind: value })),
    )
    .flat();
  const [filteredOffices, setFilteredOffices] = React.useState(offices || []);

  const defaultProps = {
    center: { lat: 40.416775, lng: -3.70379 },
    zoom: 4,
  };
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return (
    <LoadScript
      id="script-loader"
      googleMapsApiKey={process.env.GATSBY_GOOGLE_MAPS_KEY}
      language={locale}
      region="EN"
      loadingElement={
        <Box width="100%" display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      }
    >
      <Box
        style={{
          height: "70vh",
          width: "100%",
          position: "relative",
        }}
      >
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          center={defaultProps.center}
          zoom={defaultProps.zoom}
          options={{
            styles: style,
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          {filteredOffices?.length > 0 &&
            filteredOffices.map(
              (
                { attributes: { city, address, latitude, longitude }, icon },
                index,
              ) => (
                <Marker
                  key={index}
                  position={{ lat: latitude, lng: longitude }}
                  onClick={() => handleActiveMarker(index)}
                  icon={icon}
                >
                  {activeMarker === index && (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <Box width={250}>
                        <Typography mt={1} variant="h4">
                          {city}
                        </Typography>
                        <hr />
                        <Typography sx={{ "*": { margin: 0 } }}>
                          {parse(address)}
                        </Typography>
                      </Box>
                    </InfoWindow>
                  )}
                </Marker>
              ),
            )}
        </GoogleMap>
        <Box
          display="flex"
          alignItems="start"
          sx={{
            position: "absolute",
            top: { xs: "1rem", sm: "5rem" },
            left: { xs: "1rem", sm: "5rem" },
          }}
        >
          <FontAwesomeIcon
            style={{
              padding: "0.5rem",
              background: "white",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            icon={faRotateRight}
            onClick={() => setFilteredOffices(offices)}
          />
          <Box
            sx={{
              background: "white",
              borderRadius: "5px",
              p: { xs: 1, sm: 2 },
              ml: { xs: 1, sm: 2 },
            }}
          >
            <Stack spacing={1}>
              {officeKinds().map(({ kind, icon, value }) => (
                <Stack
                  sx={{ cursor: "pointer" }}
                  direction="row"
                  spacing={2}
                  onClick={() =>
                    setFilteredOffices(
                      offices.filter(({ kind }) => kind === value),
                    )}
                >
                  <img src={icon} alt="" />
                  <Typography>{kind}</Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
    </LoadScript>
  );
}

export default Map;
