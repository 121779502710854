import React from "react";
import { Stack, Typography, Box, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import styled from "@emotion/styled";

const data = () => [
  {
    title: <Trans>Origins</Trans>,
    year: "2000",
    description: (
      <Trans>
        Allfunds was founded as a Spanish bank, and is the first platform of its
        kind in Europe to offer an efficient and secure way of bridging the gap
        between fund houses and distributors.
      </Trans>
    ),
  },
  {
    title: <Trans>Beginning of International Expansion</Trans>,
    year: "2003",
    description: (
      <Trans>
        Italian Sanpaolo (Intesa Sanpaolo as of 2007) has acquired 50% of
        Allfunds. Allfunds has expanded internationally with a new office in
        Milan to cater to local clients.
      </Trans>
    ),
  },
  {
    title: <Trans>London hub</Trans>,
    year: "2005",
    description: (
      <Trans>
        The London branch has opened to serve local and global distributors.
      </Trans>
    ),
  },
  {
    title: <Trans>Luxembourg</Trans>,
    year: "2007",
    description: (
      <Trans>
        We have incorporated Allfunds International, a Luxembourg PSF, to
        provide easier fund access to European clients.
      </Trans>
    ),
  },
  {
    title: <Trans>Expanding to LatAm</Trans>,
    year: "2009",
    description: (
      <Trans>
        The Santiago de Chile office has opened to serve Latin American, and in
        particular Chilean and Colombian clients interested in international
        funds.
      </Trans>
    ),
  },
  {
    title: <Trans>Middle East opening</Trans>,
    year: "2011",
    description: (
      <Trans>
        We have ventured into the Middle East with a representative office in
        Dubai, becoming the first Shariah-compliant fund platform.
      </Trans>
    ),
  },
  {
    title: <Trans>Switzerland</Trans>,
    year: "2013",
    description: (
      <Trans>
        Allfunds Switzerland has opened in Zurich, expanding business to the
        world’s premier private banking centre.
      </Trans>
    ),
  },
  {
    title: <Trans>Expanding our service offering</Trans>,
    year: "2014",
    description: (
      <Trans>
        Allfunds International has obtained a banking license in Luxembourg,
        providing the necessary authorisation to render both banking and
        investment services.
      </Trans>
    ),
  },
  {
    title: <Trans>Bogota</Trans>,
    year: "2015",
    description: (
      <Trans>
        The Bogota office has opened strengthening our position and commitment
        to the region.
      </Trans>
    ),
  },
  {
    title: <Trans>Reaching the Asian market</Trans>,
    year: "2016",
    description: (
      <Trans>
        Expansion has continued with local licenses obtained in Singapore and
        Brazil.
      </Trans>
    ),
  },
  {
    title: <Trans>Acquisition by H&F, GIC</Trans>,
    year: "2017",
    description: (
      <Trans>
        Hellman & Friedman and GIC completed the acquisition of Allfunds from
        Intesa Sanpaolo Group and Santander Group and take majority ownership.
        Assets under distribution reached €350 billion with over 630 funds
        houses and 590 distributors. Allfunds has expanded its product range to
        incorporate ETFs on the platform and launched APIs.
      </Trans>
    ),
  },
  {
    title: <Trans>Growing our value proposition</Trans>,
    year: "2018",
    description: (
      <Trans>
        Allfunds completed its first acquisition in company history by
        purchasing fintech company Finametrix. It was also the year that it saw
        a tremendous amount of growth in teams across the globe with several
        senior hires and new offices in Singapore, Madrid and Sao Paulo.
      </Trans>
    ),
  },
  {
    title: <Trans>BNP partnership</Trans>,
    year: "2019",
    description: (
      <Trans>
        Allfunds and BNP Paribas have announced a strategic partnership to
        create one of the world’s leading fund and WealthTech platforms. Assets
        under distribution have reached over €600Bn with over 1,550 Fund Houses
        and more than 660 distributors in 50 countries. We have acquired Credit
        Suisse InvestLab, representing the largest combination in the platform
        space to date. Credit Suisse has become minority shareholders in
        Allfunds. Allfunds has acquired fundinfo's Zurich-based fund research
        business and has strengthened its data management capabilities by taking
        advantage of fundinfo as a fund data source. We completed the deal with
        Nordic Fund Market, making; Allfunds is the largest fund distribution
        network in the region. Allfunds has reinforced its digital offering with
        the launch of three tools to enhance distributor and fund manager
        experiences: Digital on-boarding for fund houses, Allfunds Connect and
        Telemetrics Market Intelligence
      </Trans>
    ),
  },
  {
    title: <Trans>New reach and scope</Trans>,
    year: "2020",
    description: (
      <Trans>
        Allfunds has seen the launch of several new and enhanced digital
        products. Through the Connect digital eco-system, Allfunds has launched
        Digital Selector and the Portfolio Optimisation tool to conduct
        sophisticated analysis and research of funds. Allfunds has continued its
        global expansion with the opening of new offices in Hong Kong, Paris,
        Warsaw and Miami. Allfunds has also announced a plan to launch their new
        sub-advisory business and Allfunds Blockchain. In early Q4 Allfunds
        completed the transaction with BNP and increased its assets to over €1
        trillion. BNP has become a minority shareholder. Allfunds has also
        joined the UN Global Compact, the world's largest corporate
        sustainability initiative and has agreed to adhere to the Principles for
        Responsible Investing.
      </Trans>
    ),
  },
  {
    title: <Trans>Becoming a public company</Trans>,
    year: "2021",
    description: (
      <Trans>
        Allfunds closed the year with EUR 1.5 trillion in assets under
        management. The new business entity, Allfunds Blockchain, has announced
        its partnership with ConsenSys, further accelerating the revolution of
        blockchain in the fund distribution industry. Allfunds digital
        eco-system, Connect, continues to roll-out enhancements, in particular,
        new ESG functionality to facilitate the fund selection process. Allfunds
        remains committed to sustainability and CSR through various projects
        around the world. In April, Allfunds (TICKER ALLFG) was listed on
        Euronext Amsterdam.
      </Trans>
    ),
  },
  {
    title: <Trans>Strengthening our digital ecosystem</Trans>,
    year: "2022",
    description: (
      <Trans>
        With nearly €1.3 trillion in assets under administration, Allfunds
        continued to grow in size and services. During the year Allfunds
        enhanced its digital ecosystem, Allfunds Connect investing in new ESG
        and advanced risk functionalities. Allfunds also strengthened its data
        analytics and software solutions with the strategic acquisitions of
        WebFG and instiHub. Additionally, Allfunds announced the intention to
        acquire a majority share of ESG specialist, MainStreet Partners.
        Allfunds Blockchain, in collaboration with other parties and local
        regulators, made history with the launch of the first-ever tokenized
        fund.
      </Trans>
    ),
  },
  {
    title: <Trans>Progressing our digital proposition</Trans>,
    year: "2023",
    description: (
      <Trans>
        Allfunds started the year by announcing the successful closing of a
        majority share of MainStreet Partners, a move designed to reinforce
        Allfunds’ ESG capacities. WebFG and instiHub, now Allfunds Tech
        Solutions and Allfunds Data Analytics, are fully integrated as business
        lines and are quickly becoming the rising stars of the WealthTech Space
        thanks to their expertise combined with Allfunds’ powerful resources and
        extensive network.
      </Trans>
    ),
  },
  {
    title: <Trans>A stronger Allfunds</Trans>,
    year: "2024",
    description: (
      <>
        <Trans>
          In 2024, Allfunds experienced steady growth, expanded its assets, and
          prioritized exceptional client experiences. The company reached
          significant milestones, such as achieving the £100 billion mark in the
          UK and strengthening the team with strategic senior hires to increase
          its market presence. These efforts paved the way for key developments,
          including expanding into new markets, forging strategic partnerships,
          and advancing in artificial intelligence (AI).
        </Trans>
        <br />
        <Trans>
          A notable achievement was Allfunds' strategic partnership with Google
          Cloud, leveraging AI-driven technologies to enhance services and
          operational efficiency. This collaboration, along with the further
          development and refinement of tools like Navigator, Regulatory
          Reporting, and Fund Registration, among others, reinforced Allfunds'
          position as a leader in innovation while delivering exceptional value
          to clients.
        </Trans>
        <br />
        <Trans>
          All these achievements underscore the company's commitment to
          embracing emerging technologies, advancing its technological
          capabilities, enhancing its value offering, and increasing its global
          presence and leadership!
        </Trans>
      </>
    ),
  },
  {
    title: <Trans>Allfunds - Strong Performance and a Bright Future</Trans>,
    year: "2025",
    description: (
      <>
        <Trans>
          Allfunds continues to solidify its position as the preferred strategic
          WealthTech partner, achieving record performance in 2024 with
          significant growth in Assets under Administration (AuA). The firm has
          delivered robust results across its integrated platforms—Long-only,
          Private Markets, and ETP. Notably, the alternative platform has shown
          significant potential since its inception, while the ETP platform is
          set to deliver its inaugural results this year, laying a solid
          foundation for sustained growth.
        </Trans>
        <br />
        <Trans>
          By leveraging advancements in artificial intelligence and fostering
          strategic partnerships, Allfunds continues to strengthen its
          leadership in the WealthTech sector. Looking ahead, the organization
          remains committed to capitalizing on emerging opportunities and
          delivering exceptional value to its stakeholders.
        </Trans>
      </>
    ),
  },
];

let WIDTH_DEFAULT = 360;
let GAP_DEFAULT = 48;
const HEIGHT_DEFAULT = 240;

function Timeline() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [showDescription, setShowDescription] = React.useState(undefined);
  const currentData = data().reverse();
  const currentYear = currentData[activeIndex]?.year;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  WIDTH_DEFAULT = isMobile ? 290 : WIDTH_DEFAULT;
  GAP_DEFAULT = isMobile ? 24 : GAP_DEFAULT;

  return (
    <Box sx={{ overflow: "hidden" }}>
      <StyledInner activeIndex={activeIndex}>
        {currentData.map(({ title, year, description }, index) => {
          const show = showDescription === index;
          const isAvailable = currentYear === year;
          return (
            <StyledCard
              mx={isMobile ? 1 : 2}
              p={2}
              show={show}
              isAvailable={isAvailable}
              onClick={() =>
                isAvailable && setShowDescription(show ? undefined : index)}
            >
              <Stack spacing={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h3" color="secondary.main">
                    {year}
                  </Typography>
                  <StyledIcon
                    redColor
                    icon={show ? faChevronUp : faChevronDown}
                    size="2x"
                  />
                </Box>
                <Typography variant="h3" color="white">
                  {title}
                </Typography>
                {show && (
                  <Typography variant="small" color="white">
                    {description}
                  </Typography>
                )}
              </Stack>
            </StyledCard>
          );
        })}
      </StyledInner>

      <Box sx={{ position: "relative", mt: { xs: 5, sm: 10 } }}>
        <Box
          sx={{
            position: "absolute",
            borderTop: (theme) => `2px solid ${theme.palette.secondary.main}`,
            width: "100%",
            zIndex: "-1",
            top: "50%",
          }}
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <StyledIcon
            icon={faChevronLeft}
            onClick={() => {
              setActiveIndex(
                activeIndex === 0 ? currentData.length - 1 : activeIndex - 1,
              );
              setShowDescription(undefined);
            }}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              background: (theme) => theme.palette.secondary.main,
              width: "116px",
              height: "116px",
              borderRadius: "50%",
            }}
          >
            <Typography color="white" variant="h3">
              {currentYear}
            </Typography>
          </Box>
          <StyledIcon
            icon={faChevronRight}
            onClick={() => {
              setActiveIndex(
                activeIndex === currentData.length - 1 ? 0 : activeIndex + 1,
              );
              setShowDescription(undefined);
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
}

const StyledInner = styled.div`
  transform: translate(
    -${({ activeIndex }) => activeIndex * (WIDTH_DEFAULT + GAP_DEFAULT)}px
  );
  transition: transform 0.3s;
  display: flex;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  background: white;
  padding: 0.5rem;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  color: ${({ redColor, theme }) =>
    redColor && `${theme.palette.secondary.main}`};
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.160784));
`;

const StyledCard = styled(Box)(
  ({ theme, show, isAvailable }) => `
  min-height: ${HEIGHT_DEFAULT}px;
  height: ${!show && `${HEIGHT_DEFAULT}px`};
  background: ${theme.palette.primary.main};
  opacity: ${!isAvailable && "0.5"};
  border-radius: 5px;
  cursor: ${isAvailable && "pointer"};
  width: ${WIDTH_DEFAULT}px;
  min-width: ${WIDTH_DEFAULT}px;
  ${theme.breakpoints.up("sm")} {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.305882);
  }
`,
);

export default Timeline;
